<div class="general-info-section-wrapper">
  <section class="mat-elevation-z8 white-box form-container">

    <div class="row">
      <div class="col-md-12">
        <div class="section-title">
          <h3>Schedule Downloads</h3>
        </div>
      </div>
    </div>

    <div class="row">
      <mat-list class="reports-list">

        <a href="/api/Download/GetAllScheduleEquipment?municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Equipment Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Download/GetAllScheduleFineArt?municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Fine Art Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Download/GetAllSchedulePayroll?municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Payroll Schedule</div>
          </mat-list-item>
        </a>
   
        <a href="/api/Download/GetAllScheduleProperty?municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Locations & Property Values Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Download/GetAllScheduleVehicle?municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Vehicle Schedule</div>
          </mat-list-item>
        </a>

        <a href="/api/Download/GetAllScheduleWatercraft?municipalityYear={{activeMunicipality.municipalityYear}}" target="_blank">
          <mat-list-item>
            <mat-icon mat-list-icon class="pdf-icon"></mat-icon>
            <div mat-line>Watercraft Schedule</div>
          </mat-list-item>
        </a>

      </mat-list>
    </div>

  </section>
</div>
