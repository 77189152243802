<section class="storage-tank-inventory-section-wrapper">
  <!-- Locations Dropdown -->
  <section class="mat-elevation-z8 white-box form-container">
    <div class="row">
      <div class="col-md-12">
        <mat-label>Select Location:</mat-label>
        <mat-form-field class="field-full-width" appearance="fill">
          <mat-select (selectionChange)="changeActiveTankLocation($event.value)" [disabled]="!activeMunicipality">
            <mat-option *ngFor="let tankLocation of locationsDropdown" [value]="tankLocation">
              {{tankLocation.companyName}} - {{tankLocation.address}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Location Details Form -->
    <form [formGroup]="detailsForm" (ngSubmit)="submitLocationDetails()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-section-title">
            <h6>Location Information</h6>
          </div>
        </div>

        <div class="col-md-4">
          <!-- Facility Name -->
          <mat-label>Facility Name</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FacilityName" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <!-- Facility Address -->
          <mat-label>Facility Address</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FacilityAddress" placeholder="" />
            <mat-error>Character length for this field is 100</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <!-- Facility ID -->
          <mat-label>Facility ID</mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input matInput formControlName="FacilityId" placeholder="" />
            <mat-error>Character length for this field is 50</mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <!-- Effective Date -->
          <mat-label>Effective Date <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" matInput formControlName="EffectiveDate" [matDatepicker]="EffectiveDate" />
            <mat-datepicker-toggle matSuffix [for]="EffectiveDate"></mat-datepicker-toggle>
            <mat-datepicker #EffectiveDate></mat-datepicker>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>

        <!-- Save Button -->
        <div class="form-action-buttons col-md-12">
          <button type="submit" mat-raised-button color="success" [disabled]="detailsForm.disabled" class="submit-btn float-right">Save</button>
        </div>
      </div>
    </form>
  </section>

  <!-- Tanks Section -->
  <section class="mat-elevation-z8 white-box form-container" [hidden]="!showTanksForm"> 
    <div class="row">
      <div class="col-md-12">
        <div class="form-section-title">
          <h6>Complete schedule with symbols below</h6>
        </div>
      </div>

      <!-- Tanks Datatable -->
      <div class="row">
        <div class="col-md-12 ">
          <!--Table-->
          <table class="datatable" mat-table [dataSource]="dataSourceTanks">
            <ng-container matColumnDef="tankNo">
              <th mat-header-cell *matHeaderCellDef> Tank No </th>
              <td mat-cell *matCellDef="let element"> {{element.tankNo}} </td>
            </ng-container>
            <ng-container matColumnDef="usT_AST">
              <th mat-header-cell *matHeaderCellDef> UST/AST </th>
              <td mat-cell *matCellDef="let element"> {{element.usT_AST}} </td>
            </ng-container>
            <ng-container matColumnDef="installDateYear">
              <th mat-header-cell *matHeaderCellDef> Install Date Year </th>
              <td mat-cell *matCellDef="let element"> {{element.installDateYear}} </td>
            </ng-container>
            <ng-container matColumnDef="capacity">
              <th mat-header-cell *matHeaderCellDef> Capacity </th>
              <td mat-cell *matCellDef="let element"> {{element.capacity}} </td>
            </ng-container>
            <!-- Action -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <div style="white-space: nowrap;">
                  <mat-icon mat-raised-button class="mr-1" type="button" color="primary" (click)="updateTankDialog(element)">mode_edit</mat-icon>
                  <mat-icon mat-raised-button class="ml-1" type="button" color="warn" (click)="deleteTankDialog(element)">delete</mat-icon>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <button mat-raised-button type="button" color="primary" class="my-5" [disabled]="detailsForm.disabled" (click)="openNewTankDialog()">Add New Tank</button>
        </div>
      </div>



    </div>

    
  </section>
</section>
